@use '~@amzn/awsui-design-tokens/polaris' as awsui;

.filter-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    order: 0;
    flex-grow: 10;
    margin-inline-end: 0;
    margin-block-end: calc(-1 * #{awsui.$space-scaled-m});

    > *:not(:empty) {
        margin-inline-end: awsui.$space-scaled-m;
        margin-block-end: awsui.$space-scaled-s;
    }
}

.filter-input {
    order: 0;
    flex-grow: 6;
    inline-size: auto;
    max-inline-size: 728px;
}

.filter-select {
    max-inline-size: 130px;
    flex-grow: 2;
    inline-size: auto;
}

.filtering-results {
    display: block;
    margin-inline-start: 1rem;
    padding-block-end: calc(1px + #{awsui.$space-scaled-xxs});
    color: awsui.$color-text-form-default;
}

@media (max-width: 1152px) {
    .filter-container {
        margin-inline-end: calc(-1 * #{awsui.$space-scaled-m});
    }
    .filter-select {
        max-inline-size: none;
    }

    .filter-input {
        inline-size: 100%;
        max-inline-size: none;
    }
}
